import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'
import s from './styles.module.scss'

const Section = ({ children, className, center }) => {
  return (
    <section className={cx(s.section, center && s.center, className)}>
      {children}
    </section>
  )
}

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  size: PropTypes.string,
  box: PropTypes.bool,
  center: PropTypes.bool,
}

export default Section
