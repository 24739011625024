import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import s from './styles.module.scss'
import { P } from 'library'

const Message = ({ children, className, ...props }) => {
  if (!children) return null
  return (
    <P className={cx(s.message, className)} {...props}>
      {children}
    </P>
  )
}

Message.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  className: PropTypes.string,
}

export default Message
