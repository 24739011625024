import React from 'react'
import PropTypes from 'prop-types'
import Banner from 'library/content/Banner'

import { Helmet } from 'react-helmet'
import classnames from 'classnames'

import s from './styles.module.scss'

const slash = u => {
  if (!u) return ''
  return u.charAt(0) === '/' ? u.slice(1) : u
}

const Page = ({
  children,
  footer,
  title,
  description,
  image,
  url,
  className,
  banner,
}) => {
  const path = 'https://www.firetower.us/'
  const url2 = path + slash(url)
  const image2 = path + slash(image)
  return (
    <>
      <main className={classnames(s.page, className)}>
        {banner && <Banner>{banner}</Banner>}
        {children}

        <Helmet>
          <title>{title}</title>
          {description && <meta name="description" content={description} />}

          {/* <!-- Open Graph / Facebook --> */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={url2} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          {image && <meta property="og:image" content={image2} />}

          {/* <!-- Twitter --> */}
          {image && (
            <meta property="twitter:card" content="summary_large_image" />
          )}
          <meta property="twitter:url" content={url2} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
          {image && <meta property="twitter:image" content={image2} />}
        </Helmet>
      </main>
      {footer}
    </>
  )
}

Page.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  banner: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  footer: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
}

Page.defaultProps = {
  title: 'Fire Tower',
  url: '',
}

export default Page
