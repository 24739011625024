import { React, PropTypes, Component, LoadImg } from 'library'
const px =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
/* eslint-disable  */

class Img extends Component {
  init() {
    this.img = React.createRef()
    const AsyncImg = LoadImg(this.props.src, true)
    AsyncImg.preload()
    return {
      AsyncImg,
    }
  }

  render() {
    const { AsyncImg } = this.state
    const { src, ...props } = this.props
    return (
      <React.Suspense fallback={<img src={px} {...props} reload="" />}>
        <AsyncImg
          {...props}
          //forwardRef={this.img}
        />
      </React.Suspense>
    )
  }
}

Img.propTypes = {
  src: PropTypes.func,
}

export default Img
