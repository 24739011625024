import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'
import s from './styles.module.scss'
import { Img } from 'library'

const H1Img = ({ className, ...props }) => {
  return <Img className={cx(s.img, className)} {...props} />
}

H1Img.propTypes = {
  className: PropTypes.string,
}

export default H1Img
