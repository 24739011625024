import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import s from './styles.module.scss'

const H2 = ({ children, darkMode, className }) => {
  return (
    <h2 className={classnames(s.h2, darkMode && s.darkMode, className)}>
      {children}
    </h2>
  )
}

H2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  className: PropTypes.string,
}

export default H2
