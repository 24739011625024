import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import s from './styles.module.scss'

const H3 = ({ children, darkMode, className }) => {
  return (
    <h3 className={classnames(s.h3, darkMode && s.darkMode, className)}>
      {children}
    </h3>
  )
}

H3.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  className: PropTypes.string,
}

export default H3
