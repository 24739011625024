import { imported } from 'react-imported-component/macro'
import React from 'react'
const px =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
/* eslint-disable react/display-name */
const LoadImg = (importFunction, async) => {
  if (!importFunction) return
  return imported(importFunction, {
    async,
    render: (Icon, state, { forwardRef, reload, ...props }) => {
      //  console.log(Icon, state)

      const newProps = {
        ...props,
        src: Icon || px,
      }
      // reload a new image, for svg's with animation
      if (reload && Icon) newProps.src = newProps.src + '?reload=' + Date.now()
      if (forwardRef) newProps.ref = forwardRef
      return <img alt={props.title || ''} {...newProps} />
    },
  })
}

export default LoadImg
