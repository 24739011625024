import React from 'react'
import PropTypes from 'prop-types'

import Page from 'library/layouts/Page'
import classnames from 'classnames'

import s from './styles.module.scss'

const ErrorPage = ({ className }) => {
  return (
    <Page className={classnames(s.page, className)} metaTitle="Error">
      Something went wrong.
    </Page>
  )
}

ErrorPage.propTypes = {
  className: PropTypes.string,
  retry: PropTypes.func,
}

export default ErrorPage
