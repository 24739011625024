import React from 'react'
import PropTypes from 'prop-types'

class Component extends React.Component {
  constructor(props) {
    super(props)
    if (this.init) this.state = this.init(props)
  }
}

export { React, PropTypes, Component }
