import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
// import s from './styles.module.scss'

const Q = ({ children, single, className }) => {
  return (
    <span className={cx(className)}>
      {!single ? <>&#8221;{children}&#8220;</> : <>&#8216;{children}&#8217;</>}
    </span>
  )
}

Q.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  single: PropTypes.bool,
}

export default Q
