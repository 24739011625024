import PropTypes from 'prop-types'
import React from 'react'
// import s from './styles.module.scss'
import TextField from '../TextField'

// TODO: add "zxcvbn" password validation on client
class Password extends React.Component {
  constructor() {
    super()
    this.toggle = this.toggle.bind(this)
    this.validate = this.validate.bind(this)
    this.state = {
      type: 'password',
    }
  }

  toggle() {
    const type = this.state.type === 'password' ? 'text' : 'password'
    this.setState({ type })
  }
  validate(value) {
    if (value.length < 8) return 'Password must be at least 8 characters'
    const { confirm } = this.props
    if (confirm && confirm !== value) return 'Password must match'
  }

  render() {
    const { type } = this.state
    const { placeholder, name, label } = this.props
    // const lock = (
    //   <button
    //     className={s.toggle}
    //     onClick={this.toggle}
    //     title={type === 'password' ? 'Show password' : 'Hide password'}>
    //     <span role="img">
    //       {type === 'password' ? <>&#128274;</> : <>&#128275;</>}
    //     </span>
    //   </button>
    // )

    return (
      <React.Fragment>
        <TextField
          {...this.props}
          type={type}
          placeholder={placeholder}
          name={name}
          label={
            <span>
              {label}
              {/* {lock} */}
            </span>
          }
          validate={this.validate}
          required
        />
      </React.Fragment>
    )
  }
}

Password.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  confirm: PropTypes.string,
}

Password.defaultProps = {
  placeholder: '*******',
  label: 'Password',
  name: 'Password',
}

export default Password
