import React from 'react'
import PropTypes from 'prop-types'
import { Button, H1Img } from 'library'

import cx from 'classnames'
import s from './styles.module.scss'

const Header = ({ menu, back, children, bgImg, className }) => {
  return (
    <header className={cx(s.header, className)}>
      {bgImg && <H1Img src={bgImg} className={s.h1img} />}
      {back && (
        <Button className={s.back} small styleName="blueOutline" to={back.to}>
          <span>
            <span className={s.arrow}>&larr;</span> {back.text}
          </span>
        </Button>
      )}
      {menu && <nav className={s.back}>{menu}</nav>}
      {children && <div className={s.content}>{children}</div>}
    </header>
  )
}

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string,
  ]),
  menu: PropTypes.element,
  back: PropTypes.object,
  className: PropTypes.string,
  bgImg: PropTypes.func,
}

export default Header
