import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import s from './styles.module.scss'

const P = ({
  children,
  center,
  className,
  nested,
  darkMode,
  highlight,
  ...rest
}) => {
  const props = {
    children,
    className: cx(
      s.p,
      center && s.center,
      darkMode && s.darkMode,
      highlight && s.highlight,
      className
    ),
    ...rest,
  }
  return !nested ? <p {...props} /> : <span {...props} />
}

P.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  className: PropTypes.string,
  center: PropTypes.bool,
  nested: PropTypes.bool,
  darkMode: PropTypes.bool,
}

export default P
