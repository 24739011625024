import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'
import s from './styles.module.scss'
import { Column, Box } from 'library'

const TwoColumn = ({ children, left, right, className }) => {
  let l = left
  let ls
  let r = right
  let rs
  if (children) {
    if (left && !right) {
      r = children
      ls = s.sub
      rs = s.main
    } else {
      l = children
      rs = s.sub
      ls = s.main
    }
  }

  return (
    <Column box className={cx(s.columns, className)}>
      <Box className={cx(s.column, ls)}>{l}</Box>
      <Box className={cx(s.column, rs)}>{r}</Box>
    </Column>
  )
}

TwoColumn.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string,
  ]),
  left: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string,
  ]),
  right: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  size: PropTypes.string,
}

export default TwoColumn
