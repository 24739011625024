import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import s from './styles.module.scss'
import { Box } from 'library'

const Icon = ({ src, alt = 'Icon', className }) => {
  return (
    <Box className={cx(s.icon, className)}>
      <img src={src} alt={alt} />
    </Box>
  )
}

Icon.propTypes = {
  className: PropTypes.string,
}

export default Icon
