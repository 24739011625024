
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import s from './styles.module.scss'

const Li = ({ children, b = <>&#10033;</>, className }) => {
  return (
    <li className={classnames(s.li, className)}>
      {b && (
        <b className={s.b}>
          <nobr>{b}</nobr>
        </b>
      )}
      <span className={s.span}>{children}</span>
    </li>
  )
}

Li.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  b: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  className: PropTypes.string,
  center: PropTypes.bool,
}

export default Li
