import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import s from './styles.module.scss'
import { P, Link } from 'library'

const TextLink = ({ className, ...props }) => {
  return (
    <P className={cx(s.link, className)}>
      <Link {...props} />
    </P>
  )
}

TextLink.propTypes = {
  className: PropTypes.string,
}

export default TextLink
