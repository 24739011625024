import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import s from './styles.module.scss'
const Block = ({ children, className }) => {
  return <p className={classnames(s.block, className)}>{children}</p>
}

Block.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  className: PropTypes.string,
}

export default Block
