import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'
import s from './styles.module.scss'
import { Box } from 'library'

const Column = ({ children, className, size, box, ...props }) => {
  if (box)
    return (
      <Box className={classnames(s.column, s[size], className)} {...props}>
        {children}
      </Box>
    )
  return (
    <div className={classnames(s.column, s[size], className)} {...props}>
      {children}
    </div>
  )
}

Column.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  size: PropTypes.string,
  box: PropTypes.bool,
}

export default Column
