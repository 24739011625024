import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect as RouterRedirect } from 'react-router-dom'

const Redirect = ({ to, ...props }) => {
  const external = typeof to === 'string' && to.charAt(0) !== '/'

  useEffect(() => {
    if (!external) return
    const ext = to.indexOf('http') !== 0 ? 'http://' + to : to
    window.location = ext
  }, [external, to])

  if (external) return null
  return <RouterRedirect {...props} to={to} />
}

Redirect.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default Redirect
