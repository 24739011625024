import { React, Row, Mini, B } from 'library'

import s from './styles.module.scss'
const Banner = ({ children }) => {
  return (
    <Row className={s.banner}>
      {typeof children === 'string' ? (
        <Mini>
          <B>{children}</B>
        </Mini>
      ) : (
        children
      )}
    </Row>
  )
}

Banner.propTypes = {}

export default Banner
