import PropTypes from 'prop-types'
import React from 'react'
import { NavLink, Link as Link2 } from 'react-router-dom'
import s from './styles.module.scss'
import classnames from 'classnames'
import { externalUrl } from './helpers'
import A from './A'

class Link extends React.Component {
  component = null
  external = null
  path = null

  UNSAFE_componentWillMount() {
    const { to, activeClassName } = this.props

    this.external = externalUrl(to)
    this.path = typeof to === 'object' ? to.pathname : to || ''

    if (!to || this.external) this.component = A
    else this.component = activeClassName ? NavLink : Link2
  }
  render() {
    /* eslint-disable react/display-name */
    /* eslint-disable no-unused-vars */
    /* eslint-disable react/prop-types */
    const { children, onClick, to, className, newTab, ...props } = this.props
    const newProps = {
      ...props,
      to,
      className: classnames(s.link, className),
      onClick,
      target: newTab ? '_blank' : null,
    }

    const Component = this.component
    return <Component {...newProps}>{children}</Component>
  }
}

Link.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  newTab: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  activeClassName: PropTypes.string,
}

export default Link
