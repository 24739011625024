import PropTypes from 'prop-types'
import React from 'react'
import useEvent from 'elements/analytics/useEvent'

const A = ({ to, title, className, onClick, children, newTab }) => {
  const event = useEvent()
  const aProps = {
    title,
    onClick: () => {
      if (onClick) onClick()
      // ie a submit button - track event elsewhere
      if (!onClick || !to) return
      const name = to || onClick.name
      if (name)
        event(name, {
          label: 'LINK',
        })
    },
    className,
  }
  if (to) {
    aProps.href = to
    aProps.target = newTab === false ? null : '_blank'
  }
  return <a {...aProps}>{children}</a>
}

A.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  newTab: PropTypes.bool,
  to: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
}

export default A
